import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FluxDnaSettings } from "../Footer/FluxDnaSettings";
import "../Header/Header.scss";
const axios = require("axios").default;
var CryptoJS = require("crypto-js");

const RoyalMemberClub = (props) => {
  const formId = 25;
  const menuElement = useRef();
  const menuElementBooking = useRef();
  const [menuOpenStatus, setMenuOpenStatus] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpenBooking, setMenuOpenBooking] = useState(false);
  const fluxFooterSetting = FluxDnaSettings();
  const [isImage, setisImage] = useState(null);
  const [stepForm, setStepForm] = useState(1);
  const [isLoding, setIsLoading] = useState(false);
  const [nxtDate, setNxtDate] = useState("");
  const [checked, setChecked] = useState(false);
  const [chkSum, setChkSum] = useState("");
  const [payid, setPayId] = useState("");
  const [payRef, setPayRef] = useState("");
  const inputEl = useRef(null);


  const [form, setForm] = useState({
    input_1: "",
    input_2: "",
    input_3: "",
    input_4: "",
    input_5: "",
    input_6_1: "",
    input_6_2: "",
    input_6_3: "",
    input_6_4: "",
    input_6_5: "",
    input_6_6: "",
    input_6: "",
    input_8: "",
    input_13: "",
    input_12: payRef,
  });

  const [formError, setFormError] = useState({
    input_1: "",
    input_2: "",
    input_3: "",
    input_4: "",
    input_5: "",
    input_6: "",
    input_8: "",
    input_13: "",
  });

 useEffect(()=>{

  document.body.classList.remove("loading");

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }
    return result;
  }

  const paymentRef = makeid(10);
  setForm({
    ...form,
    input_12: paymentRef,
  })
  setPayRef(paymentRef);

 },[setPayRef, setForm])

  const handleSuccess = (data) => {

    let param_data = {
      PAYGATE_ID: `${process.env.GATSBY_PAYGATE_ID}`,
      REFERENCE: payRef,
      AMOUNT: 1500000,
      CURRENCY: "ZAR",
      RETURN_URL: "https://www.theroyalportfolio.com/royal-members-club/",
      TRANSACTION_DATE: new Date().toISOString(),
      LOCALE: "en-za",
      COUNTRY: "ZAF",
      EMAIL: form.input_3,
    };

    const generateMD5 = (obj, secret = `${process.env.GATSBY_PAYGATE_SECRET}`) => {
      let str = "";
      for (let val in obj) {
        str += obj[val];
      }
      str += secret;
      return CryptoJS.MD5(str).toString();
    };

    const generate_md5 = generateMD5(param_data);
    param_data = { ...param_data, CHECKSUM: generate_md5 };
    param_data = new URLSearchParams(param_data).toString();
    var isRequestFinished = false;

    axios
      .post("https://secure.paygate.co.za/payweb3/initiate.trans", param_data)
      .then((response) => {
        const response_data = response.data.split("&");
        const pay_id = response_data[1].split("=");
        const pay_ref = response_data[2].split("=");
        const chk_sum_id = response_data[3].split("=");
        setPayId(pay_id[1]);
        setChkSum(chk_sum_id[1]);
        isRequestFinished = true;
        if (isRequestFinished) {
          sendMail();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const sendMail = () => {
      
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      setFormError({
        ...formError,
        input_1:
          form.input_1 === ""
            ? "Please enter first name."
            : "",
        input_2:
          form.input_2 === ""
            ? "Please enter last name."
            : "",
        input_3:
          form.input_3 === ""
            ? "Please enter your email."
            : "",
        input_4:
          form.input_4 === ""
            ? "Please enter your phone number."
            : "",
        input_5:
          form.input_5 === ""
            ? "Please enter your South African ID Number"
            : "",
        input_8:
          form.input_8 === ""
            ? "Please accept Terms and Conditions."
            : "",
        input_13:
          form.input_13 === ""
            ? "Please agree to receive updates and consent to data storage to proceed."
            : "",
      });

      if (
        form.input_1 !== "" &&
        form.input_2 !== "" &&
        form.input_3 !== "" &&
        form.input_8 !== "" &&
        form.input_13 !== ""
      ) {
        document.body.classList.add("loader");
        setIsLoading(true);
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(form),
        };
        const url = `${process.env.GATSBY_WP_URL}wp-json/gf/v2/forms/${formId}/submissions`;
        setFormError({
          ...formError,
          input_26: "",
        });
        fetch(url, requestOptions).then((response) => {
          if (response.status === 200) {
            document.body.classList.remove("loader");
            setIsLoading(false);
            inputEl.current.submit();
          } else {
            setStepForm(1);
            setFormError({
              ...formError,
              input_1: "Please try again.",
            });
          }
        });
      }
  }


  return (
    <>
      <section className="traveller-enquiry-form bg-white">
        <div className="flex flex-wrap">
          <div className="lg:w-12/12 w-full ">
            <div
              className="xl:px-80 lg:px-40
             px-20 lg:py-40 pb-20 pt-20 relative"
            >
              <div className="scroll-form-main">
                <div className="top-info flex flex-wrap items-center justify-between">
                  <h1 className="text-black-500 lg:text-34">Royal Members Club</h1>
                </div>
                <form className="lg:mt-30 mt-20">
                  <div className="form-wrapper">
                    <div className="flex flex-wrap lg:mx-minus-20">
                      <div className="lg:w-6/12 w-full lg:px-20">
                        <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                        
                          <label
                            className="w-full text-brown text-6"
                            htmlFor="first-name"
                          >
                            First Name*
                          </label>
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            value={form.input_1}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                input_1: e.target.value,
                              });
                              setFormError({
                                ...formError,
                                input_1: "",
                              });
                            }}
                            placeholder="Type here..."
                          />
                        </div>

                        {formError.input_1 && (
                          <div className="error">
                            <span className="text-red">
                              {formError.input_1}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="lg:w-6/12 w-full lg:px-20">
                        <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                          <label
                            className="w-full text-brown text-6"
                            htmlFor="last-name"
                          >
                            Last Name*
                          </label>
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            value={form.input_2}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                input_2: e.target.value,
                              });
                              setFormError({
                                ...formError,
                                input_2: "",
                              });
                            }}
                            placeholder="Type here..."
                          />
                        </div>

                        {formError.input_2 && (
                          <div className="error">
                            <span className="text-red">
                              {formError.input_2}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="lg:w-6/12 w-full lg:px-20">
                        <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                          <label
                            className="w-full text-brown text-6"
                            htmlFor="email"
                          >
                            Email*
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="email"
                            value={form.input_3}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                input_3: e.target.value,
                              });
                              setFormError({
                                ...formError,
                                input_3: "",
                              });
                            }}
                            placeholder="Type here..."
                          />
                        </div>

                        {formError.input_3 && (
                          <div className="error">
                            <span className="text-red">
                              {formError.input_3}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="lg:w-6/12 w-full lg:px-20">
                        <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                          <label
                            className="w-full text-brown text-6"
                            htmlFor="tel"
                          >
                            Mobile Phone Number*
                          </label>
                          <input
                            type="tel"
                            name="tel"
                            id="tel"
                            autoComplete="tel"
                            value={form.input_4}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                input_4: e.target.value,
                              });
                              setFormError({
                                ...formError,
                                input_4: "",
                              });
                            }}
                            placeholder="Type here..."
                          />
                        </div>

                        {formError.input_4 && (
                          <div className="error">
                            <span className="text-red">
                              {formError.input_4}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="lg:w-12/12 w-full lg:px-20">
                        <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                          <label
                            className="w-full text-brown text-6"
                            htmlFor=""
                          >
                            South African ID Number*
                          </label>
                          <input
                            type="text"
                            value={form.input_5}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                input_5: e.target.value,
                              });
                              setFormError({
                                ...formError,
                                input_5: "",
                              });
                            }}
                            placeholder="Type here..."
                          />
                        </div>

                        {formError.input_5 && (
                          <div className="error">
                            <span className="text-red">
                              {formError.input_5}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="lg:mx-minus-20">
                    <div className="lg:w-12/12 w-full lg:px-20">
                      <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                        <label
                          className="w-full text-brown text-18 text-black-100 mb-10"
                          htmlFor=""
                        >
                          Address in South Africa (to receive your complimentary copy of A
                          Perfect Stay)
                        </label>
                        <label> Street Address</label>
                        <input
                          type="text"
                          value={form.input_6_1}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              input_6_1: e.target.value,
                            });
                            setFormError({
                              ...formError,
                              input_6_1: "",
                            });
                          }}
                          placeholder="Type here..."
                        />
                      </div>

                      {formError.input_6_1 && (
                        <div className="error">
                          <span className="text-red">
                            {formError.input_6_1}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="lg:mx-minus-20">
                    <div className="lg:w-12/12 w-full lg:px-20">
                      <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                        <label className="w-full text-brown text-6" htmlFor="">
                          Address Line 2
                        </label>
                        <input
                          type="text"
                          value={form.input_6_2}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              input_6_2: e.target.value,
                            });
                            setFormError({
                              ...formError,
                              input_6_2: "",
                            });
                          }}
                          placeholder="Type here..."
                        />
                      </div>

                      {formError.input_6_2 && (
                        <div className="error">
                          <span className="text-red">
                            {formError.input_6_2}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-wrap lg:mx-minus-20">
                    <div className="lg:w-6/12 w-full lg:px-20">
                      <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                        <label className="w-full text-brown text-6" htmlFor="">
                          City
                        </label>
                        <input
                          type="text"
                          value={form.input_6_3}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              input_6_3: e.target.value,
                            });
                            setFormError({
                              ...formError,
                              input_6_3: "",
                            });
                          }}
                          placeholder="Type here..."
                        />
                      </div>

                      {formError.input_6_3 && (
                        <div className="error">
                          <span className="text-red">
                            {formError.input_6_3}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="lg:w-6/12 w-full lg:px-20">
                      <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                        <label className="w-full text-brown text-6" htmlFor="">
                          State / Province / Region
                        </label>
                        <input
                          type="text"
                          value={form.input_6_4}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              input_6_4: e.target.value,
                            });
                            setFormError({
                              ...formError,
                              input_6_4: "",
                            });
                          }}
                          placeholder="Type here..."
                        />
                      </div>

                      {formError.input_6_4 && (
                        <div className="error">
                          <span className="text-red">
                            {formError.input_6_4}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="lg:w-6/12 w-full lg:px-20">
                      <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                        <label className="w-full text-brown text-6" htmlFor="">
                          ZIP / Postal Code
                        </label>
                        <input
                          type="text"
                          value={form.input_6_5}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              input_6_5: e.target.value,
                            });
                            setFormError({
                              ...formError,
                              input_6_5: "",
                            });
                          }}
                          placeholder="Type here..."
                        />
                      </div>

                      {formError.input_6_5 && (
                        <div className="error">
                          <span className="text-red">
                            {formError.input_6_5}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="lg:w-6/12 w-full lg:px-20">
                      <div className="form-group flex flex-wrap lg:mt-30 mt-10">
                        <label className="w-full text-brown text-6" htmlFor="">
                          Country
                        </label>
                        <select
                          value={form.input_6_6}
                          onChange={(e) => {
                            setForm({
                              ...form,
                              input_6_6: e.target.value,
                            });
                            setFormError({
                              ...formError,
                              input_6_6: "",
                            });
                          }}
                        >
                          <option value="">Select Country</option>
                          <option value="Afghanistan">Afghanistan</option>
                          <option value="Albania">Albania</option>
                          <option value="Algeria">Algeria</option>
                          <option value="American Samoa">American Samoa</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                          <option value="Anguilla">Anguilla</option>
                          <option value="Antarctica">Antarctica</option>
                          <option value="Antigua and Barbuda">
                            Antigua and Barbuda
                          </option>
                          <option value="Argentina">Argentina</option>
                          <option value="Armenia">Armenia</option>
                          <option value="Aruba">Aruba</option>
                          <option value="Australia">Australia</option>
                          <option value="Austria">Austria</option>
                          <option value="Azerbaijan">Azerbaijan</option>
                          <option value="Bahamas">Bahamas</option>
                          <option value="Bahrain">Bahrain</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Barbados">Barbados</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Belgium">Belgium</option>
                          <option value="Belize">Belize</option>
                          <option value="Benin">Benin</option>
                          <option value="Bermuda">Bermuda</option>
                          <option value="Bhutan">Bhutan</option>
                          <option value="Bolivia">Bolivia</option>
                          <option value="Bonaire, Sint Eustatius and Saba">
                            Bonaire, Sint Eustatius and Saba
                          </option>
                          <option value="Bosnia and Herzegovina">
                            Bosnia and Herzegovina
                          </option>
                          <option value="Botswana">Botswana</option>
                          <option value="Bouvet Island">Bouvet Island</option>
                          <option value="Brazil">Brazil</option>
                          <option value="British Indian Ocean Territory">
                            British Indian Ocean Territory
                          </option>
                          <option value="Brunei Darussalam">
                            Brunei Darussalam
                          </option>
                          <option value="Bulgaria">Bulgaria</option>
                          <option value="Burkina Faso">Burkina Faso</option>
                          <option value="Burundi">Burundi</option>
                          <option value="Cambodia">Cambodia</option>
                          <option value="Cameroon">Cameroon</option>
                          <option value="Canada">Canada</option>
                          <option value="Cape Verde">Cape Verde</option>
                          <option value="Cayman Islands">Cayman Islands</option>
                          <option value="Central African Republic">
                            Central African Republic
                          </option>
                          <option value="Chad">Chad</option>
                          <option value="Chile">Chile</option>
                          <option value="China">China</option>
                          <option value="Christmas Island">
                            Christmas Island
                          </option>
                          <option value="Cocos Islands">Cocos Islands</option>
                          <option value="Colombia">Colombia</option>
                          <option value="Comoros">Comoros</option>
                          <option value="Congo, Democratic Republic of the">
                            Congo, Democratic Republic of the
                          </option>
                          <option value="Congo, Republic of the">
                            Congo, Republic of the
                          </option>
                          <option value="Cook Islands">Cook Islands</option>
                          <option value="Costa Rica">Costa Rica</option>
                          <option value="Croatia">Croatia</option>
                          <option value="Cuba">Cuba</option>
                          <option value="Curaçao">Curaçao</option>
                          <option value="Cyprus">Cyprus</option>
                          <option value="Czech Republic">Czech Republic</option>
                          <option value="Côte d'Ivoire">Côte d'Ivoire</option>
                          <option value="Denmark">Denmark</option>
                          <option value="Djibouti">Djibouti</option>
                          <option value="Dominica">Dominica</option>
                          <option value="Dominican Republic">
                            Dominican Republic
                          </option>
                          <option value="Ecuador">Ecuador</option>
                          <option value="Egypt">Egypt</option>
                          <option value="El Salvador">El Salvador</option>
                          <option value="Equatorial Guinea">
                            Equatorial Guinea
                          </option>
                          <option value="Eritrea">Eritrea</option>
                          <option value="Estonia">Estonia</option>
                          <option value="Eswatini (Swaziland)">
                            Eswatini (Swaziland)
                          </option>
                          <option value="Ethiopia">Ethiopia</option>
                          <option value="Falkland Islands">
                            Falkland Islands
                          </option>
                          <option value="Faroe Islands">Faroe Islands</option>
                          <option value="Fiji">Fiji</option>
                          <option value="Finland">Finland</option>
                          <option value="France">France</option>
                          <option value="French Guiana">French Guiana</option>
                          <option value="French Polynesia">
                            French Polynesia
                          </option>
                          <option value="French Southern Territories">
                            French Southern Territories
                          </option>
                          <option value="Gabon">Gabon</option>
                          <option value="Gambia">Gambia</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Germany">Germany</option>
                          <option value="Ghana">Ghana</option>
                          <option value="Gibraltar">Gibraltar</option>
                          <option value="Greece">Greece</option>
                          <option value="Greenland">Greenland</option>
                          <option value="Grenada">Grenada</option>
                          <option value="Guadeloupe">Guadeloupe</option>
                          <option value="Guam">Guam</option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guernsey">Guernsey</option>
                          <option value="Guinea">Guinea</option>
                          <option value="Guinea-Bissau">Guinea-Bissau</option>
                          <option value="Guyana">Guyana</option>
                          <option value="Haiti">Haiti</option>
                          <option value="Heard and McDonald Islands">
                            Heard and McDonald Islands
                          </option>
                          <option value="Holy See">Holy See</option>
                          <option value="Honduras">Honduras</option>
                          <option value="Hong Kong">Hong Kong</option>
                          <option value="Hungary">Hungary</option>
                          <option value="Iceland">Iceland</option>
                          <option value="India">India</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Iran">Iran</option>
                          <option value="Iraq">Iraq</option>
                          <option value="Ireland">Ireland</option>
                          <option value="Isle of Man">Isle of Man</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Jamaica">Jamaica</option>
                          <option value="Japan">Japan</option>
                          <option value="Jersey">Jersey</option>
                          <option value="Jordan">Jordan</option>
                          <option value="Kazakhstan">Kazakhstan</option>
                          <option value="Kenya">Kenya</option>
                          <option value="Kiribati">Kiribati</option>
                          <option value="Kuwait">Kuwait</option>
                          <option value="Kyrgyzstan">Kyrgyzstan</option>
                          <option value="Lao People's Democratic Republic">
                            Lao People's Democratic Republic
                          </option>
                          <option value="Latvia">Latvia</option>
                          <option value="Lebanon">Lebanon</option>
                          <option value="Lesotho">Lesotho</option>
                          <option value="Liberia">Liberia</option>
                          <option value="Libya">Libya</option>
                          <option value="Liechtenstein">Liechtenstein</option>
                          <option value="Lithuania">Lithuania</option>
                          <option value="Luxembourg">Luxembourg</option>
                          <option value="Macau">Macau</option>
                          <option value="Macedonia">Macedonia</option>
                          <option value="Madagascar">Madagascar</option>
                          <option value="Malawi">Malawi</option>
                          <option value="Malaysia">Malaysia</option>
                          <option value="Maldives">Maldives</option>
                          <option value="Mali">Mali</option>
                          <option value="Malta">Malta</option>
                          <option value="Marshall Islands">
                            Marshall Islands
                          </option>
                          <option value="Martinique">Martinique</option>
                          <option value="Mauritania">Mauritania</option>
                          <option value="Mauritius">Mauritius</option>
                          <option value="Mayotte">Mayotte</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Micronesia">Micronesia</option>
                          <option value="Moldova">Moldova</option>
                          <option value="Monaco">Monaco</option>
                          <option value="Mongolia">Mongolia</option>
                          <option value="Montenegro">Montenegro</option>
                          <option value="Montserrat">Montserrat</option>
                          <option value="Morocco">Morocco</option>
                          <option value="Mozambique">Mozambique</option>
                          <option value="Myanmar">Myanmar</option>
                          <option value="Namibia">Namibia</option>
                          <option value="Nauru">Nauru</option>
                          <option value="Nepal">Nepal</option>
                          <option value="Netherlands">Netherlands</option>
                          <option value="New Caledonia">New Caledonia</option>
                          <option value="New Zealand">New Zealand</option>
                          <option value="Nicaragua">Nicaragua</option>
                          <option value="Niger">Niger</option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="Niue">Niue</option>
                          <option value="Norfolk Island">Norfolk Island</option>
                          <option value="North Korea">North Korea</option>
                          <option value="Northern Mariana Islands">
                            Northern Mariana Islands
                          </option>
                          <option value="Norway">Norway</option>
                          <option value="Oman">Oman</option>
                          <option value="Pakistan">Pakistan</option>
                          <option value="Palau">Palau</option>
                          <option value="Palestine, State of">
                            Palestine, State of
                          </option>
                          <option value="Panama">Panama</option>
                          <option value="Papua New Guinea">
                            Papua New Guinea
                          </option>
                          <option value="Paraguay">Paraguay</option>
                          <option value="Peru">Peru</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Pitcairn">Pitcairn</option>
                          <option value="Poland">Poland</option>
                          <option value="Portugal">Portugal</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Romania">Romania</option>
                          <option value="Russia">Russia</option>
                          <option value="Rwanda">Rwanda</option>
                          <option value="Réunion">Réunion</option>
                          <option value="Saint Barthélemy">
                            Saint Barthélemy
                          </option>
                          <option value="Saint Helena">Saint Helena</option>
                          <option value="Saint Kitts and Nevis">
                            Saint Kitts and Nevis
                          </option>
                          <option value="Saint Lucia">Saint Lucia</option>
                          <option value="Saint Martin">Saint Martin</option>
                          <option value="Saint Pierre and Miquelon">
                            Saint Pierre and Miquelon
                          </option>
                          <option value="Saint Vincent and the Grenadines">
                            Saint Vincent and the Grenadines
                          </option>
                          <option value="Samoa">Samoa</option>
                          <option value="San Marino">San Marino</option>
                          <option value="Sao Tome and Principe">
                            Sao Tome and Principe
                          </option>
                          <option value="Saudi Arabia">Saudi Arabia</option>
                          <option value="Senegal">Senegal</option>
                          <option value="Serbia">Serbia</option>
                          <option value="Seychelles">Seychelles</option>
                          <option value="Sierra Leone">Sierra Leone</option>
                          <option value="Singapore">Singapore</option>
                          <option value="Sint Maarten">Sint Maarten</option>
                          <option value="Slovakia">Slovakia</option>
                          <option value="Slovenia">Slovenia</option>
                          <option value="Solomon Islands">
                            Solomon Islands
                          </option>
                          <option value="Somalia">Somalia</option>
                          <option value="South Africa">
                                    South Africa
                                  </option>
                          <option value="South Georgia">South Georgia</option>
                          <option value="South Korea">South Korea</option>
                          <option value="South Sudan">South Sudan</option>
                          <option value="Spain">Spain</option>
                          <option value="Sri Lanka">Sri Lanka</option>
                          <option value="Sudan">Sudan</option>
                          <option value="Suriname">Suriname</option>
                          <option value="Svalbard and Jan Mayen Islands">
                            Svalbard and Jan Mayen Islands
                          </option>
                          <option value="Sweden">Sweden</option>
                          <option value="Switzerland">Switzerland</option>
                          <option value="Syria">Syria</option>
                          <option value="Taiwan">Taiwan</option>
                          <option value="Tajikistan">Tajikistan</option>
                          <option value="Tanzania">Tanzania</option>
                          <option value="Thailand">Thailand</option>
                          <option value="Timor-Leste">Timor-Leste</option>
                          <option value="Togo">Togo</option>
                          <option value="Tokelau">Tokelau</option>
                          <option value="Tonga">Tonga</option>
                          <option value="Trinidad and Tobago">
                            Trinidad and Tobago
                          </option>
                          <option value="Tunisia">Tunisia</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Turkmenistan">Turkmenistan</option>
                          <option value="Turks and Caicos Islands">
                            Turks and Caicos Islands
                          </option>
                          <option value="Tuvalu">Tuvalu</option>
                          <option value="US Minor Outlying Islands">
                            US Minor Outlying Islands
                          </option>
                          <option value="Uganda">Uganda</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">
                            United Arab Emirates
                          </option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                          <option value="Uruguay">Uruguay</option>
                          <option value="Uzbekistan">Uzbekistan</option>
                          <option value="Vanuatu">Vanuatu</option>
                          <option value="Venezuela">Venezuela</option>
                          <option value="Vietnam">Vietnam</option>
                          <option value="Virgin Islands, British">
                            Virgin Islands, British
                          </option>
                          <option value="Virgin Islands, U.S.">
                            Virgin Islands, U.S.
                          </option>
                          <option value="Wallis and Futuna">
                            Wallis and Futuna
                          </option>
                          <option value="Western Sahara">Western Sahara</option>
                          <option value="Yemen">Yemen</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Zimbabwe">Zimbabwe</option>
                          <option value="Åland Islands">Åland Islands</option>
                        </select>
                      </div>

                      {formError.input_6 && (
                        <div className="error">
                          <span className="text-red">{formError.input_6}</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mt-30 form-group">
                    <p>Pricing per membership is R15 000.00</p>
                    <div className="pt-5">
                      <div className="radio-bx checkbox-bx relative">
                        <input
                          type="checkbox"
                          value="Yes"
                          checked={checked}
                          onChange={(e) => {
                            setChecked(e.target.checked);
                            setForm({
                              ...form,
                              input_8: e.target.value,
                            });
                          }}
                          id="input_39_1"
                        />
                        <label
                          htmlFor="input_39_1"
                          className="text-dark text-16 !pb-0"
                        >
                          I agree to the 
                          <a 
                          href="https://wp.theroyalportfolio.com/app/uploads/2022/08/Royal-Members-Club-Terms-and-Conditions.pdf"
                          target="_blank"
                          className="cursor-pointer text-gold">
                            &nbsp;Terms & Conditions of The Royal Members Club.
                          </a>
                        </label>
                        {formError.input_8 && (
                          <div className="error">
                            <span className="text-red">
                              {formError.input_8}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group text-area flex flex-wrap mt-10">
                    <div className="radio-bx checkbox-bx">
                      <input
                        type="checkbox"
                        value="Yes"
                        checked={form.input_13 === 'Yes'}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setForm({
                            ...form,
                            input_13: checked ? 'Yes' : '',
                          });
                        }}
                        id="input_13"
                      />
                      <label
                        htmlFor="input_13"
                        className="text-dark text-16 !pb-0"
                      >
                        I agree to receive news and updates and consent to my submitted data being collected and stored*
                      </label>
                      {formError.input_13 && (
                          <div className="error">
                            <span className="text-red">
                              {formError.input_13}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="text-center inline-block mt-20 w-full">
                    <a
                      onClick={handleSuccess}
                      className="button button-gray cursor-pointer"
                    >
                      Sign Up
                    </a>
                  </div>
                </form>
                <div>
                  <form
                    ref={inputEl}
                    action="https://secure.paygate.co.za/payweb3/process.trans"
                    id="credit_form"
                    method="POST"
                  >
                    <input type="hidden" name="PAY_REQUEST_ID" value={payid} />
                    <input type="hidden" name="CHECKSUM" value={chkSum} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RoyalMemberClub;
export const Head = () => (
  <title>Royal Members Club | The Royal Portfolio</title>
);
